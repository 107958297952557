import React, { useEffect, useState } from "react";
import { useElementOnScreen } from "./hooks";
import clsx from "clsx";
import { objectToQueryString, useDpr } from "modules/utils";
import { useMediaPredicate } from "react-media-hook";

export enum ImageTag {
  DIV = "div",
  IMG = "img",
  SPAN = "span",
}

type LazyloadImageProps = {
  alt?: string;
  ariaLabel?: string;
  className?: string;
  desktop?: {
    aspectRatio?: string;
    height: number;
    width: number;
  };
  icon?: boolean;
  mobile?: {
    aspectRatio?: string;
    height: number;
    width: number;
  };
  onClick?: () => void;
  role?: string;
  src?: string;
  tagName: any;
};

const LazyloadImage: React.FC<LazyloadImageProps> = ({
  alt,
  ariaLabel,
  children,
  className,
  desktop,
  icon,
  mobile,
  onClick,
  role,
  src,
  tagName,
}) => {
  const Tag = tagName;
  const isMob = useMediaPredicate("(max-width: 360px)");
  const [bg, setBg] = useState("none");
  const [img, setImg] = useState("/images/placeholder.png");
  const [loaded, setLoaded] = useState(false);
  const { containerRef, isVisible } = useElementOnScreen({ root: null, rootMargin: "0px", threshold: 0 });

  const params = {
    width: isMob ? mobile?.width : desktop && desktop?.width,
    height: isMob ? mobile?.height : desktop && desktop?.height,
    fit: "bounds",
    crop: `${isMob ? mobile?.aspectRatio : desktop?.aspectRatio},smart`,
    format: !icon ? "jpg" : null,
    auto: "webp",
    quality: "60",
    dpr: useDpr(),
  };

  useEffect(() => {
    if (isVisible && src) {
      Tag === "img" ? setImg(src) : setBg(src);
      setLoaded(true);
    }
  }, [isVisible]);

  const classNames = clsx(className, "lazyload", loaded && "lazyloaded");

  if (Tag === "img") {
    return (
      <img
        alt={alt}
        aria-label={ariaLabel}
        className={classNames}
        onClick={onClick}
        ref={containerRef}
        role={role}
        src={`${img}?${objectToQueryString(params)}`}
        title={alt}
      />
    );
  }
  return (
    <Tag
      ariaLabel={ariaLabel}
      className={classNames}
      onClick={onClick}
      ref={containerRef}
      role={role}
      style={{ backgroundImage: bg === "none" ? bg : `url("${bg}?${objectToQueryString(params)}")` }}
    >
      {children}
    </Tag>
  );
};

export default React.memo(LazyloadImage);
