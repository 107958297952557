import React from "react";
import { usePageReference } from "modules/unions/usePageReference";

import { CtaProps } from "./types";
import * as Hyperlink from "components/Hyperlink";

const CtaHyperlink: React.FC<CtaProps> = ({
  ariaLabel,
  children,
  className,
  colour,
  custom_url,
  defaultColour,
  disabled,
  label,
  open_in,
  page_link,
  target,
}) => {
  const slug = page_link !== undefined && page_link[0];
  if (slug) {
    return (
      <Hyperlink.PageLink
        ariaLabel={ariaLabel}
        className={className}
        disabled={disabled}
        label={label ? label : "Learn more"}
        slug={usePageReference(slug)}
        style={colour ? colour : defaultColour}
        target={target}
      >
        {children}
      </Hyperlink.PageLink>
    );
  }
  if (custom_url) {
    return (
      <Hyperlink.CustomLink
        ariaLabel={ariaLabel}
        className={className}
        disabled={disabled}
        href={custom_url}
        label={label ? label : "Learn more"}
        style={colour ? colour : defaultColour}
        target={open_in || target}
      >
        {children}
      </Hyperlink.CustomLink>
    );
  }
  return null;
};

export default React.memo(CtaHyperlink);
