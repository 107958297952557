import React from "react";
import { usePageReference } from "../../modules/unions/usePageReference";

import { CtaProps } from "./types";
import * as Button from "components/Button";

const CtaButton: React.FC<CtaProps> = ({
  ariaLabel,
  children,
  className,
  colour,
  custom_url,
  defaultColour,
  disabled,
  label,
  itemProp,
  roundedCorner,
  onClick,
  open_in,
  page_link,
  target,
}) => {
  const slug = page_link !== undefined && page_link[0];
  if (slug) {
    return (
      <Button.PageLink
        ariaLabel={ariaLabel}
        className={className}
        disabled={disabled}
        label={label ? label : "Learn more"}
        itemProp={itemProp}
        roundedCorner={roundedCorner}
        slug={usePageReference(slug)}
        style={colour ? colour : defaultColour}
        target={target}
      >
        {children}
      </Button.PageLink>
    );
  }
  if (custom_url) {
    return (
      <Button.CustomLink
        ariaLabel={ariaLabel}
        className={className}
        disabled={disabled}
        href={custom_url}
        label={label ? label : "Learn more"}
        itemProp={itemProp}
        roundedCorner={roundedCorner}
        onClick={onClick}
        style={colour ? colour : defaultColour}
        target={open_in || target}
      >
        {children}
      </Button.CustomLink>
    );
  }
  return null;
};
export default React.memo(CtaButton);
