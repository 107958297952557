import React from "react";
import clsx from "clsx";

import { HyperlinkPageLinkProps } from "./types";
import { Link } from "gatsby";

import * as styles from "./styles.module.scss";

const PageLink: React.FunctionComponent<HyperlinkPageLinkProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  label,
  slug,
  style,
  target,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };

  const hyperlinkClassName = clsx(
    style && styles.hyperlink,
    styles[`${style?.replace(/\s/g, "")}`],
    disabled && styles.disabled,
    className
  );

  return (
    <Link
      aria-label={ariaLabel ? ariaLabel : label}
      className={hyperlinkClassName}
      onMouseDown={handleMouseDown}
      tabIndex={disabled ? -1 : 0}
      target={target}
      to={slug ? slug : "/"}
    >
      {label && <span>{label}</span>}
      {children}
    </Link>
  );
};

export default React.memo(PageLink);
