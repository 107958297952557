import { useEffect, useRef, useState } from "react";

/* -------------------------------------------------------------------------- */
/* -- https://dev.to/producthackers/intersection-observer-using-react-49ko -- */
/* -------------------------------------------------------------------------- */

export const useElementOnScreen = (options: any) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries: any) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current as any);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current as any);
    };
  }, [containerRef, options]);

  return { containerRef, isVisible };
};
