// extracted by mini-css-extract-plugin
export var hyperlink = "styles-module--hyperlink--QODAR";
export var Aqua = "styles-module--Aqua--BVly8";
export var BlackGrape = "styles-module--BlackGrape--IzXyK";
export var Berry = "styles-module--Berry--4c9sT";
export var White = "styles-module--White--8TW0V";
export var ProductProPath = "styles-module--ProductProPath--jXbQW";
export var ProductItil = "styles-module--ProductItil--X7HK8";
export var ProductTransformation = "styles-module--ProductTransformation--SZ-EC";
export var ProductMaturity = "styles-module--ProductMaturity--aLjAB";
export var ProductResilia = "styles-module--ProductResilia--OVUeL";
export var disabled = "styles-module--disabled--aB7xI";