export type LogoReferenceProps = {
  companyLogo?: LogoProps;
  productLogo?: LogoProps;
};

export type LogoProps = {
  dimension: {
    height: number;
    width: number;
  };
  title: string;
  url: string;
};

export const useLogoReference = (logo: LogoReferenceProps) => {
  const logoNameKey = logo !== null && Object?.keys(logo)[1];
  switch (logoNameKey) {
    case "companyLogo":
      return logo?.companyLogo;
    case "productLogo":
      return logo?.productLogo;
    default:
      return null;
  }
};
