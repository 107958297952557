import React from "react";
import clsx from "clsx";

import { hasStartAndEndTag, htmlSafe, stripParagraphTags } from "modules/utils";
import { ProductCardsItemProps } from "../types";
import { useLogoReference } from "modules/unions/useLogoReference";
import * as Cta from "components/Cta";
import LazyloadImage from "modules/lazyload/Image";

import * as styles from "./styles.module.scss";

export enum LogoSize {
  REGULAR = "Regular",
  SMALL = "Small",
}

const Card: React.FunctionComponent<ProductCardsItemProps> = ({
  body: { description, cta_format, cta, sub_heading },
  forwardedRef,
  header: { background_colour, copy, logo, logo_size = LogoSize.REGULAR },
  height,
}) => {
  const className = clsx(
    background_colour && styles[`${background_colour?.replace(/\s/g, "")}`],
    logo && styles[`${logo_size?.replace(/\s/g, "")}`],
    cta_format === "Button" && (cta?.custom_url !== "" || cta?.page_link.length > 0) && styles.hasButton,
    styles.card,
    "card"
  );
  const renderContent = htmlSafe(description);
  const safeContent = hasStartAndEndTag(description) ? renderContent : <p>{renderContent}</p>;

  return (
    <div className={className}>
      <header style={{ height: `${height}px` }}>
        <div ref={forwardedRef}>
          {logo && logo.length !== 0 && <LazyloadImage icon src={useLogoReference(logo[0])?.url} tagName="div" />}
          {copy && <p>{htmlSafe(stripParagraphTags(copy))}</p>}
        </div>
        <span className={styles.divider}></span>
      </header>
      <div className={styles.cardInfo}>
        {sub_heading && <h3>{sub_heading}</h3>}
        {description && safeContent}
        {cta_format === "Button" ? (
          <Cta.Button className={styles.button} colour={cta.colour} {...cta} />
        ) : (
          <Cta.Hyperlink className={styles.link} {...cta} />
        )}
      </div>
    </div>
  );
};

export default Card;
