import React from "react";
import { Link } from "gatsby";

import useSiteMetadata from "modules/siteurl";

import "modules/breadcrumbs/styles.scss";
import { htmlSafe, wrapRegisteredSymbol } from "modules/utils";

export type BreadcrumbProps = {
  crumbs: any;
  currentPageTitle: string;
};

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ crumbs, currentPageTitle }) => {
  const { siteUrl } = useSiteMetadata();
  return (
    <div className="breadcrumbs">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol itemScope itemType="https://schema.org/BreadcrumbList">
            {crumbs.map((i: any, key: any) => {
              const safeLabel = <span itemProp="name">{htmlSafe(wrapRegisteredSymbol(i.crumbLabel))}</span>;
              return (
                <li key={key} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                  {crumbs.length === key + 1 ? (
                    <span itemProp="name">{htmlSafe(wrapRegisteredSymbol(currentPageTitle))}</span>
                  ) : (
                    <Link
                      to={i.pathname}
                      className="link"
                      itemScope
                      itemType="https://schema.org/WebPage"
                      itemProp="item"
                      itemID={`${siteUrl}${i.pathname}`}
                    >
                      {safeLabel}
                    </Link>
                  )}
                  <meta itemProp="position" content={key + 1}></meta>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumbs;
