import React from "react";
import { objectToQueryString } from "modules/utils";

type PreloadImageProps = {
  alt: string;
  className?: string;
  children?: React.ReactNode;
  desktop?: {
    aspectRatio?: string;
    height: number;
    width: number;
  };
  icon?: boolean;
  mobile?: {
    aspectRatio?: string;
    height: number;
    width: number;
  };
  src?: string;
};

const PreloadImage: React.FC<PreloadImageProps> = ({ alt, children, className, desktop, icon, mobile, src }) => {
  const desktopParams = {
    width: desktop?.width,
    height: desktop?.height,
    fit: "bounds",
    crop: `${desktop?.aspectRatio},smart`,
    format: !icon ? "jpg" : null,
    auto: "webp",
    quality: "60",
  };

  const mobileParams = {
    width: mobile?.width,
    height: mobile?.height,
    fit: "bounds",
    crop: `${mobile?.aspectRatio},smart`,
    format: !icon ? "jpg" : null,
    auto: "webp",
    quality: "60",
  };

  return (
    <picture className={className}>
      <source
        srcSet={`${src}?${objectToQueryString(mobileParams)}&dpr=2`}
        media="(max-width: 360px) and (-webkit-min-device-pixel-ratio: 2), (max-width: 360px) and (min-resolution: 192dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(mobileParams)}&dpr=1.5`}
        media="(max-width: 360px) and (-webkit-min-device-pixel-ratio: 1.5), (max-width: 360px) and (min-resolution: 144dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(mobileParams)}&dpr=1.25`}
        media="(max-width: 360px) and (-webkit-min-device-pixel-ratio: 1.25), (max-width: 360px) and (min-resolution: 120dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(mobileParams)}&dpr=1`}
        media="(max-width: 360px) and (-webkit-min-device-pixel-ratio: 1), (max-width: 360px) and (min-resolution: 96dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(desktopParams)}&dpr=2`}
        media="(min-width: 361px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 361px) and (min-resolution: 192dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(desktopParams)}&dpr=1.5`}
        media="(min-width: 361px) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 361px) and (min-resolution: 144dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(desktopParams)}&dpr=1.25`}
        media="(min-width: 361px) and (-webkit-min-device-pixel-ratio: 1.25), (min-width: 361px) and (min-resolution: 120dpi)"
      />
      <source
        srcSet={`${src}?${objectToQueryString(desktopParams)}&dpr=1`}
        media="(min-width: 361px) and (-webkit-min-device-pixel-ratio: 1), (min-width: 361px) and (min-resolution: 96dpi)"
      />
      <img
        src={`${src}?${objectToQueryString(desktopParams)}`}
        alt={alt}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
      {children}
    </picture>
  );
};

export default React.memo(PreloadImage);
