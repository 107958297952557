import React from "react";
import clsx from "clsx";
import { htmlSafe, hasStartAndEndTag } from "modules/utils";

import { ImageHorizontalPosition } from "modules/images/constants";
import DividerSVG from "modules/theme/artwork/divider-grey-with-logo.svg";
import LargeDividerSVG from "modules/theme/ims/hero-carousel-divider.svg";
import { LogoSize } from "components/ProductCards/Card";
import Breadcrumbs from "modules/breadcrumbs";
import LazyloadImage from "modules/lazyload/Image";
import PreloadImage from "modules/lazyload/PreloadImage";

import "components/Hero/Banner/styles.scss";

export type HeroBannerProps = {
  background_colour: HeroColour;
  button_label?: string;
  hero_breadcrumbs?: boolean;
  children?: React.ReactNode;
  content?: string;
  crumbs?: any;
  forwardedContainerRef?: React.Ref<HTMLDivElement>;
  hero_image?: {
    url: string;
  };
  hero_size: string;
  image_horizontal_position?: ImageHorizontalPosition;
  noText?: boolean;
  overlay?: boolean;
  product_logo?: {
    logo: {
      url: string;
    };
  }[];
  product_logo_size?: LogoSize | string;
  slogan_title?: boolean;
  reverseDivider?: boolean;
  title?: string;
};

export enum HeroColour {
  BLACK_GRAPE = "BlackGrape",
  MONOCHROME_5 = "Monochrome5",
  PRO_PATH = "ProductProPath",
  MATURITY = "ProductMaturity",
  ITIL = "ProductItil",
  TRANSFORMATION = "ProductTransformation",
  RESILIA = "ProductResilia",
}

export enum HeroSize {
  LARGE = "Large",
  MEDIUM = "Medium",
  SMALL = "Small",
}

const Banner: React.FunctionComponent<HeroBannerProps> = ({
  background_colour,
  children,
  content,
  crumbs,
  forwardedContainerRef,
  hero_breadcrumbs,
  hero_image,
  hero_size,
  noText,
  overlay,
  product_logo,
  product_logo_size = LogoSize.REGULAR,
  image_horizontal_position = ImageHorizontalPosition.CENTRE,
  reverseDivider,
  slogan_title,
  title,
}) => {
  const imageHeight = () => {
    switch (hero_size) {
      case HeroSize.LARGE:
        return 800;
      case HeroSize.MEDIUM:
        return 600;
      case HeroSize.SMALL:
        return 320;
      default:
        return 600;
    }
  };
  const imageAspectRatio = () => {
    switch (hero_size) {
      case HeroSize.LARGE:
        return "12:5";
      case HeroSize.MEDIUM:
        return "16:10";
      case HeroSize.SMALL:
        return "3:1";
      default:
        return "16:10";
    }
  };

  const desktop = {
    height: imageHeight(),
    width: hero_size === HeroSize.LARGE ? 1920 : 960,
    aspectRatio: imageAspectRatio(),
  };
  const mobile = {
    height: 400,
    width: 360,
    aspectRatio: "9:10",
  };

  const safeCopy =
    content && hasStartAndEndTag(content) ? content && htmlSafe(content) : <h1>{content && htmlSafe(content)}</h1>;
  const alt = title ? title : "Powering Best Practice";

  const classNames = clsx(
    "heroBanner",
    `${hero_size}`,
    `${background_colour?.replace(/\s/g, "")}`,
    product_logo_size && `${product_logo_size}Logo`,
    slogan_title && "hasSlogan",
    image_horizontal_position && `Horizontal${image_horizontal_position}`,
    reverseDivider && "reverseDivider",
    overlay && "overlay",
    noText && "noText"
  );

  return (
    <div className={classNames}>
      {hero_breadcrumbs && <Breadcrumbs crumbs={crumbs} currentPageTitle={title} />}
      <div className="container small" ref={forwardedContainerRef}>
        {slogan_title ? (
          <h1>
            <span>Powering</span>
            <span>Best</span>
            <span>Practice</span>
          </h1>
        ) : (
          <React.Fragment>
            {product_logo && product_logo[0]?.logo && (
              <LazyloadImage className="productLogo" icon src={product_logo[0]?.logo?.url} tagName="span" />
            )}
            {safeCopy}
          </React.Fragment>
        )}
        {children}
      </div>
      {hero_size === HeroSize.LARGE ? (
        <div>
          <LargeDividerSVG className="divider" />
          <PreloadImage alt={alt} className="image" desktop={desktop} mobile={mobile} src={hero_image?.url} />
        </div>
      ) : (
        <div>
          <span className="dividerMobile"></span>
          <PreloadImage alt={alt} className="image" desktop={desktop} mobile={mobile} src={hero_image?.url}>
            <DividerSVG className="divider" />
          </PreloadImage>
        </div>
      )}
    </div>
  );
};

export default Banner;
