// extracted by mini-css-extract-plugin
export var card = "styles-module--card--0CUd4";
export var divider = "styles-module--divider--0Jv94";
export var cardInfo = "styles-module--cardInfo--6v4kr";
export var link = "styles-module--link--YHa8x";
export var button = "styles-module--button--ntg5l";
export var Regular = "styles-module--Regular--JyDOo";
export var Small = "styles-module--Small--36ilF";
export var hasButton = "styles-module--hasButton--ZAh1b";
export var BlackGrape = "styles-module--BlackGrape--1NvVt";
export var Monochrome1 = "styles-module--Monochrome1--5MxXg";
export var ProductProPath = "styles-module--ProductProPath--MuLPn";
export var ProductItil = "styles-module--ProductItil--7zXiv";
export var ProductTransformation = "styles-module--ProductTransformation--owQem";
export var ProductMaturity = "styles-module--ProductMaturity--eHysm";
export var ProductResili = "styles-module--ProductResili--lmnsN";
export var TSONavy = "styles-module--TSONavy--FzVjV";