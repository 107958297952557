import React from "react";
import clsx from "clsx";

import { addProtocol } from "modules/utils";
import { ButtonTarget } from "components/Button/constants";
import { HyperlinkCustomLinkProps } from "./types";

import * as styles from "./styles.module.scss";

const CustomLink: React.FunctionComponent<HyperlinkCustomLinkProps> = ({
  ariaLabel,
  children,
  className,
  disabled,
  href,
  label,
  onClick,
  style,
  target = ButtonTarget.BLANK,
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
  };
  const hyperlinkClassName = clsx(
    style && styles.hyperlink,
    styles[`${style?.replace(/\s/g, "")}`],
    disabled && styles.disabled,
    className
  );

  return (
    <a
      aria-label={ariaLabel ? ariaLabel : label}
      className={hyperlinkClassName}
      href={addProtocol(href)}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      tabIndex={disabled ? -1 : 0}
      target={target}
    >
      {label && <span>{label}</span>}
      {children}
    </a>
  );
};

export default React.memo(CustomLink);
